export const state = {
  moduleOpened: null,
  defaultRoutes: [
    {
      label: 'broiler',
      icon: 'rooster',
      role: 'frangos_de_corte',
      backgroundImage: require('@/assets/trinovati-images/5.jpg'),
      tooltip: {
        content: 'broiler',
        theme: 'tooltip-right',
        placement: 'right',
      },
      items: [
        {
          icon: 'fa-solid fa-chart-line fa-fw',
          label: 'performance',
          role: 'Performance',
          route: '/broiler/performance',
          dashboard: { name: 'BroilerPerformanceDashboard' },
        },
        {
          icon: 'fa-solid fa-table-list fa-fw',
          label: 'slaughter_forecast',
          role: 'Predição',
          route: '/broiler/slaughter-prediction',
          dashboard: { name: 'BroilerSlaughterPredictionDashboard' },
        },
        {
          icon: 'fa-solid fa-briefcase-medical fa-fw',
          label: 'sanity',
          role: 'Sanidade',
          route: '/broiler/sanity',
          dashboard: { name: 'BroilerSanityDashboard' },
        },
        {
          icon: 'fa-solid fa-utensils fa-fw',
          label: 'nutrition',
          role: 'Nutrição',
          route: '/broiler/nutrition',
          dashboard: { name: 'BroilerNutritionDashboard' },
        },
        {
          icon: 'fa-solid fa-exclamation-triangle fa-fw',
          label: 'alerts/recent',
          route: '/broiler/alerts/recent',
          dashboard: { name: 'BroilerAlertDashboard' },
        },
        {
          icon: 'fa-solid fa-history fa-fw',
          label: 'alerts/history',
          route: '/broiler/alerts/recent',
          dashboard: { name: 'BroilerAlertHistoryDashboard', disabled: true },
          disabled: true,
        },
      ],
    },
    {
      label: 'matrix',
      icon: 'fa-solid fa-egg fa-fw',
      role: 'matrizeiro',
      backgroundImage: require('@/assets/trinovati-images/4.jpg'),
      tooltip: {
        content: 'matrix',
        theme: 'tooltip-right',
        placement: 'right',
      },
      items: [
        {
          icon: 'fa-solid fa-chart-line fa-fw',
          label: 'performance',
          role: 'Performance',
          route: '/matrix/performance',
          dashboard: { name: 'MatrixPerformanceDashboard' },
        },
        {
          icon: 'fa-solid fa-briefcase-medical fa-fw',
          label: 'sanity',
          role: 'Sanidade',
          route: '/matrix/sanity',
          dashboard: { name: 'MatrixSanityDashboard' },
        },
        {
          icon: 'fa-solid fa-utensils fa-fw',
          label: 'nutrition',
          role: 'Nutrição',
          route: '/matrix/nutrition',
          dashboard: { name: 'MatrixNutritionDashboard' },
        },
        {
          icon: 'fa-solid fa-exclamation-triangle fa-fw',
          label: 'alerts/recent',
          route: '/matrix/alerts/recent',
          dashboard: { name: 'MatrixAlertDashboard' },
        },
        {
          icon: 'fa-solid fa-history fa-fw',
          label: 'alerts/history',
          route: '/matrix/alerts/recent',
          dashboard: { name: 'MatrixAlertHistoryDashboard', disabled: true },
          disabled: true,
        },
      ],
    },
    {
      label: 'laying-birds',
      icon: 'laying-birds',
      role: 'aves_poedeiras',
      backgroundImage: require('@/assets/trinovati-images/laying-birds.jpg'),
      tooltip: {
        content: 'laying-birds',
        theme: 'tooltip-right',
        placement: 'right',
      },
      items: [
        {
          icon: 'fa-solid fa-briefcase-medical fa-fw',
          label: 'sanity',
          role: 'Sanidade',
          route: '/laying-birds/sanity',
          dashboard: { name: 'LayingBirdsSanityDashboard' },
        },
        {
          icon: 'fa-solid fa-utensils fa-fw',
          label: 'nutrition',
          role: 'Nutrição',
          route: '/laying-birds/nutrition',
          dashboard: { name: 'LayingBirdsNutritionDashboard' },
        },
        {
          icon: 'fa-solid fa-exclamation-triangle fa-fw',
          label: 'alerts',
          route: '/laying-birds/alerts/recent',
          dashboard: { name: 'LayingBirdsAlertDashboard' },
        },
        {
          icon: 'fa-solid fa-history fa-fw',
          label: 'alerts/history',
          route: '/laying-birds/alerts/recent',
          dashboard: { name: 'LayingBirdsAlertHistoryDashboard', disabled: true },
          disabled: true,
        },
      ],
    },
    {
      label: 'swine',
      icon: 'swine',
      role: 'suinos',
      backgroundImage: require('@/assets/trinovati-images/swine.jpg'),
      tooltip: {
        content: 'swine',
        theme: 'tooltip-right',
        placement: 'right',
      },
      items: [
        {
          icon: 'fa-solid fa-utensils fa-fw',
          label: 'nutrition',
          role: 'Nutrição',
          route: '/swine/nutrition',
          dashboard: { name: 'SwineNutritionDashboard' },
        },
        {
          icon: 'fa-solid fa-exclamation-triangle fa-fw',
          label: 'alerts/recent',
          route: '/swine/alerts/recent',
          dashboard: { name: 'SwineAlertDashboard' },
        },
        {
          icon: 'fa-solid fa-history fa-fw',
          label: 'alerts/history',
          route: '/swine/alerts/recent',
          dashboard: { name: 'SwineAlertHistoryDashboard', disabled: true },
          disabled: true,
        },
      ],
    },
  ],
  routes: [],
  dashboardMap: {
    'broiler': {
      'performance': 'BroilerPerformanceDashboard',
      'slaughter-prediction': 'BroilerSlaughterPredictionDashboard',
      'sanity': 'BroilerSanityDashboard',
      'nutrition': 'BroilerNutritionDashboard',
      'alert': 'BroilerAlertDashboard',
    },
    'matrix': {
      'performance': 'MatrixPerformanceDashboard',
      'sanity': 'MatrixSanityDashboard',
      'nutrition': 'MatrixNutritionDashboard',
      'alert': 'MatrixAlertDashboard',
    },
    'laying-birds': {
      'sanity': 'LayingBirdsSanityDashboard',
      'nutrition': 'LayingBirdsNutritionDashboard',
      'alert': 'LayingBirdsAlertDashboard',
    },
    'swine': {
      'performance': 'SwinePerformanceDashboard',
      'slaughter-prediction': 'SwineSlaughterPredictionDashboard',
      'sanity': 'SwineSanityDashboard',
      'nutrition': 'SwineNutritionDashboard',
      'alert': 'SwineAlertDashboard',
    },
  },
};
