<template>
  <div class="min-h-100vh flex flex-col flex-shrink-0 drop-shadow-xl z-50">
    <div class="flex flex-row flex-grow">
      <!-- Main -->
      <div class="bg-white w-16 drop-shadow">
        <!-- Header Logo -->
        <div class="flex justify-center items-center py-2 h-14 border-b border-slate-200">
          <img class="w-14" src="@/assets/trinovati-only-logo.png" alt="Trinovati logo">
        </div>

        <!-- Menu -->
        <div class="py-2">
          <nav>
            <ul>
              <ExternalMenuItem
                label="home"
                icon="fa-solid fa-home fa-fw"
                :tooltip="{ content: 'home', theme: 'tooltip-right', placement: 'right' }"
                @click.native="home"
                :selected="$route.name === 'Home'"
              />

              <router-link v-for="(route, i) in routes" :key="i" :to="route.route || ''">
                <ExternalMenuItem
                  :label="route.label"
                  :icon="route.icon || null"
                  :tooltip="route.tooltip"
                  :items="route.items"
                  :role="route.role"
                  :selected="route.label === $route?.meta?.module"
                />
              </router-link>

              <ExternalMenuItem
                label="logout"
                icon="fa-solid fa-arrow-right-from-bracket fa-fw"
                :tooltip="{
                  content: 'logout',
                  theme: 'tooltip-right',
                  placement: 'right',
                }"
                @click.native="logout"
              />
              </ul>
          </nav>
        </div>

        <!-- Footer -->
        <div class="py-4 border-t border-slate-200 select-none">
          <LanguageDropdown :expanded="false" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LanguageDropdown from '@/components/ui/LanguageDropdown.vue';
import ExternalMenuItem from '@/components/sidebar/ExternalMenuItem.vue';

import { mapActions } from 'vuex';

export default {
  name: 'NewSideBar',
  components: { LanguageDropdown, ExternalMenuItem },
  props: {
    expanded: { type: Boolean, default: false },
    routes: { type: Array, default: () => [] },
  },
  methods: {
    ...mapActions('environment', ['resetPropertiesValues']),
    ...mapActions('comfort', ['resetEnthalpyValues']),
    ...mapActions('nutrition', ['resetNutritionValues']),
    ...mapActions('user', ['resetUserValues']),
    home() {
      if (this.$route.path !== '/') {
        this.$router.push('/');
      }
    },
    logout() {
      this.resetPropertiesValues();
      this.resetEnthalpyValues();
      this.resetNutritionValues();
      this.resetUserValues();
      this.$session.destroy();
      this.$router.push('/login');
    },
  },
};
</script>

<style lang="scss">
@use '@/assets/styles/sidebar.scss';

.min-h-100vh {
  min-height: 100vh !important;
}
</style>
