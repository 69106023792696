export const getters = {
  getToken(state) {
    return state.token;
  },

  getIsGuest(state) {
    return state.isGuest;
  },

  getUserName(state) {
    return state.name;
  },

  getUserRoles(state) {
    return state.roles;
  },
};
