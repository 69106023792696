import { authenticate } from '@/services/users';

export const actions = {
  async requestToken({ commit, dispatch }, { username, password }) {
    let response = await authenticate(username, password);

    if (response) {
      commit('SET_TOKEN', response);
      commit('SET_USER_DATA', response.user)
      commit('SET_ROLES', response.user.roles)

      dispatch('interface/updateRoutes', response.user.roles, { root: true });
    }
    return response;
  },

  resetUserValues({ commit, dispatch }) {
    commit('SET_TOKEN', null);
    commit('SET_ROLES', {});
    commit('SET_NAME', null);
    commit('SET_IS_GUEST', false);

    dispatch('interface/updateRoutes', {}, { root: true });
  },
};
