export const getters = {
  getModuleOpened(state) {
    return state.moduleOpened;
  },

  getDefaultRoutes(state) {
    return state.defaultRoutes;
  },

  getRoutes(state) {
    return state.routes;
  },

  getDashboardMap(state) {
    return state.dashboardMap;
  },
};
