export const actions = {
  updateRoutes({ commit, state }, userRoles) {
    if (Object.keys(userRoles).length === 0) return;

    const updatedRoutes = state.defaultRoutes.map((route) => {
      const newRoute = { ...route };
      newRoute.disabled = !userRoles[route.role];
      newRoute.items = newRoute.items.map((item) => ({
        ...item,
        disabled: item?.disabled || (item?.role && !userRoles[route.role]?.includes(item?.role)),
      }));

      return newRoute;
    });

    commit('SET_ROUTES', updatedRoutes);
  },
};
