import { api as axios } from '@/plugins/axios.js';

export const authenticate = async (username, password) => {
  const config = {
    method: 'post',
    url: '/v1/token/',
    data: { username, password },
  };
  const { data } = await axios(config);
  return data;
};
