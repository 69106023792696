export const mutations = {
  SET_CONFIGURED_FILTERS(state, value) {
    state.configuredFilters = value;
  },

  SET_PCP_CONFIGURED_FILTERS(state, value) {
    state.pcpConfiguredFilters = value;
  },

  SET_INDEX_FILTER(state, value) {
    state.indexFilter = value;
  },

  SET_PARAMS(state, value) {
    state.params = value;
  },

  SET_SHOW_DISABLED_PROPERTIES(state, value) {
    state.showDisabledProperties = value;
  },

  SET_PCP_INDEX_FILTER (state, value) {
    state.pcpIndexFilter = value;
  },

  SET_SHOW_SLAUGHTER_STATE(state, value) {
    state.showSlaughterState = value;
  },

  SET_SHOW_LODGE_STATE(state, value) {
    state.showLodgeState = value;
  },

  SET_SHOW_PRE_LODGE_STATE(state, value) {
    state.showPreLodgeState = value;
  },

  SET_SHOW_PRIORITY_STATE(state, value) {
    state.showPriorityState = value;
  },

  SET_REQUEST_PARAMS(state, value) {
    state.requestParams = value;
  },

  SET_FLAG_SLAUGHTER_PREDICTION(state, value) {
    state.flagSlaughterPrediction = value;
  },

  SET_SEARCH_SLAUGHTER_PREDICTION(state, value) {
    state.searchSlaughterPrediction = value;
  },

  SET_FLAG_PCP_PREDICTION(state, value) {
    state.flagPcpPrediction = value;
  },

  SET_FLAG_PCP_SIMULATION(state, value) {
    state.flagPcpSimulation = value;
  },

  SET_SEARCH_PCP_PREDICTION(state, value) {
    state.searchPcpPrediction = value;
  },
};
