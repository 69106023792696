<template>
  <li class="side-bar-menu-item external">
    <div v-if="role ? hasPermission(role) : true" 
      class="container-menu-item"
      :class="{ 'disabled': disabled, 'expanded': moduleOpened == label, 'selected': selected || moduleOpened == label }">
      <!-- Icon -->
      <FTooltip :theme="tooltip.theme" :placement="tooltip.placement">
        <div class="container-icon main" @click="toggleAccordion">
          <RoosterIcon v-if="icon == 'rooster'" class="w-5" />
          <LayingBirdsIcon v-else-if="icon == 'laying-birds'" class="w-5" />
          <SwineIcon v-else-if="icon == 'swine'" class="w-5" />
          <font-awesome-icon v-else class="side-bar-icon" :icon="icon" />
        </div>

        <template #popper>
          <span>{{ $t(tooltip.content) }}</span>
        </template>
      </FTooltip>

      <!-- Accordion -->
      <div v-if="this.getModuleOpened == label && items?.length" class="container-accordion">
        <div v-for="(item, i) in items" :key="i">
          <router-link :to="item.route || ''" :disabled="!hasPermission(role, item.role)"
            :event="hasPermission(role, item.role) ? 'click' : ''">
            <FTooltip :theme="tooltip.theme" :placement="tooltip.placement">
              <div class="container-icon" :class="[item.route == $route.path ? 'selected' : '', { 'disabled': item.disabled }]">
                <font-awesome-icon v-if="!item.disabled && hasPermission(role, item.role)" :icon="item.icon" />
                <font-awesome-icon v-else class="my-2 transition cursor-not-allowed text-gray-500" icon="fa-solid fa-lock" />
              </div>
              <template #popper>
                <span>{{ $t(item.label) }}</span>
              </template>
            </FTooltip>
          </router-link>
        </div>
      </div>
    </div>
    <div v-else class="container-menu-item disabled">
      <FTooltip :theme="tooltip.theme" :placement="tooltip.placement">
        <div class="container-icon main">
          <RoosterIcon v-if="icon == 'rooster'" class="w-5" />
          <LayingBirdsIcon v-else-if="icon == 'laying-birds'" class="w-5" />
          <SwineIcon v-else-if="icon == 'swine'" class="w-5" />
          <font-awesome-icon v-else class="side-bar-icon" :icon="icon" />
        </div>

        <template #popper>
          <span>{{ $t(tooltip.content) }}</span>
        </template>
      </FTooltip>
    </div>
  </li>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import RoosterIcon from '@/assets/icons/RoosterIcon.vue';
import LayingBirdsIcon from '@/assets/icons/LayingBirdsIcon.vue';
import SwineIcon from '@/assets/icons/SwineIcon.vue';

export default {
  name: 'ExternalMenuItem',
  components: { RoosterIcon, LayingBirdsIcon, SwineIcon },
  props: {
    label: { type: String, required: true },
    icon: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    tooltip: { type: Object },

    items: { type: Array },
    selected: { type: Boolean, default: false },

    role: { type: String, default: null }
  },
  data() {
    return {
      expandedAccordion: false,
    };  
  },
  computed: {
    ...mapGetters('interface', ['getModuleOpened']),
    ...mapGetters('user', ['getUserRoles']),
    moduleOpened: {
      get() {
        return this.getModuleOpened;
      },
      set(value) {
        this.SET_MODULE_OPENED(value);
      },
    },
  },
  methods: {
    ...mapMutations('interface', ['SET_MODULE_OPENED']),
    toggleAccordion() {
      this.moduleOpened = this.moduleOpened == this.label ? null : this.label;
    },
    hasPermission(role, item_role,) {
      return item_role ? this.getUserRoles[role]?.includes(item_role) : Boolean(this.getUserRoles[role]);
    },
  },
};
</script>
