export const mutations = {
  SET_MODULE_OPENED(state, value) {
    state.moduleOpened = value;
  },

  SET_ROUTES(state, value) {
    state.routes = value;
  },
  
  SET_ROLES(state, defaultRoles) {
    state.defaultRoles = defaultRoles;
  },
};
