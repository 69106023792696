export default {
  'hello': 'Hello, {username}.',
  'comfort': 'Comfort',
  'comfortable': 'Comfortable',
  'intermediary': 'Intermediary',
  'critical': 'Critical',
  'no_data': 'No Data',
  'lethal': 'Lethal',
  'aviaries': 'Aviaries',
  'aviary': 'Aviary',
  'loading_properties': 'Loading properties',
  'none_found': 'No properties were found',
  'empty_batch': 'Empty Batch',
  'pre_lodged': 'Pre-lodged',
  'lodged': 'Lodged',
  'slaughter': 'Slaughter',
  'storage': 'Storage',
  'simulation': 'Simulation',
  'target_weight': 'Target Weight',
  'target_value': 'Necessary Feed',
  'priority': 'Priority',
  'entry_weight': 'Entry Weight',
  'weekly_weight_projection': 'Weight Projection by Age',
  'leave_weight': 'Exit Weight',
  'leave_age': 'Exit Age',
  'filtros': 'Filters',
  'aviaries_page': 'Aviaries per Page',
  'rations_page': 'Rations per Page',
  'stage': 'Stage',
  'conversionFactor': 'Conversion Factor',
  'bed_temperature': 'Bed Temperature',
  'water_temperature': 'Water Temperature',
  'ambient-humidity': 'Ambient Humidity',
  'ambient-temperature': 'Ambient Temperature',
  'humidity-ambient-temperature': 'Ambient Humidity and Temperature',
  'shed': 'Shed',
  'ambience': 'Ambience',
  'message': 'Message',
  'alerts': 'Alerts',
  'creation_date': 'Creation Date',
  'dateTime': 'Date and Time',
  'qty24h': 'Last 24h',
  'type': 'Type',
  'subtype': 'Subtype',
  'lineage': 'Lineage',
  'sex': 'Sex',
  'age': 'Age',
  'real_age': 'Chickens Age',
  'batch_age': 'Batch Age',
  'weight': 'Weight',
  'dwg': 'D.W.G.',
  'alerts/recent': 'Alerts',
  'alerts/history': 'Alert History',
  'recent': 'Recent',
  'uniformity': 'Uniformity',
  'performance': 'Performance',
  'producer': 'Producer',
  'city': 'City',
  'greater_than': 'Greater than',
  'less_than': 'Less than',
  'alertTypeQty': 'Alert Types',
  'totalQty': 'Total Quantity',
  'quantityNf': 'Quantity NF (kg)',
  'quantityTotalDeliveredNf': 'Total Delivered Feed NF (kg)',
  'total_delivered': 'Total Delivered',
  'exactly': 'Exactly',
  'and': 'AND',
  'or': 'OR',
  'add': 'Add',
  'remove': 'Remove',
  'identification': 'Identification',
  'address': 'Address',
  'contact': 'Contact',
  'sub_region': 'Sub-region',
  'lodge': 'Lodge',
  'current_lodge': 'Current Lodge',
  'date': 'Date',
  'effective_weight': 'Effective Weight',
  'nutrition': 'Nutrition',
  'sanity': 'Sanity',
  'logout': 'Logout',
  'loading': 'Loading',
  'en': 'English',
  'es': 'Spanish',
  'pt-br': 'Portuguese',
  'slaughter_forecast': 'Slaughter Forecast',
  'forecast': 'Forecast',
  'close': 'Close',
  'last_projected_weight': 'Last Projected Weight',
  'actions': 'Actions',
  'predicted_weight': 'Predicted Weight',
  'select_a_date': 'Select a Date',
  'days': 'Days',
  'production_days': 'Production Days',
  'weeks': 'Weeks',
  'production_weeks': 'Production Weeks',
  'production_time': 'Production Time',
  'prediction': 'Prediction',
  'predictions': 'Predictions',
  'fwu': 'F.W.U.',
  'search': 'Search',
  'broiler': 'Broiler Chicken',
  'broiler_chicken': 'Broiler Chicken',
  'matrix': 'Breeder',
  'swine': 'Swine',
  'swines': 'Swines',
  'home': 'Home',
  'status': 'Status',
  'silo': 'Silo',
  'ration': 'Ration',
  'rationType': 'Ration Type',
  'capacity': 'Capacity',
  'free': 'Free',
  'available': 'Available',
  "last_received_time": 'Last Reading',
  'occupation': 'Occupation',
  'expected_end_date': 'Expected End Date',
  'full': 'Full',
  'low': 'Low',
  'satisfactory': 'Satisfactory',
  'last_ration_delivered': 'Last Ration Delivered',
  'ration_delivered': 'Ration Delivered',
  'consumed': 'Consumed',
  'pre_initial': 'Pre-Initial',
  'initial': 'Initial',
  'growth': 'Growth',
  'general': 'General',
  'male': 'Male',
  'female': 'Female',
  'production_chicken': 'Production Birds',
  'hatch_chicken': 'Breeding Birds',
  'production': 'Production',
  'hatch': 'Hatch',
  'production_type': 'Production Type',
  'aerosacculitis': 'Aerosacculitis',
  'contaminated': 'Contaminated Birds',
  'total_delivered_ration': 'Total Delivered Ration',
  'total_consumed': 'Total Consumed',
  'core': 'Core',
  'pcp': 'PCP Planning',
  'pcp_filter': 'PCP',
  'dwg_filter': 'DWG',
  'weighing': 'Weighing',
  'StorageCapacity': 'Storage Capacity',
  'TotalFeedInTheField': 'Total Feed in the Field',
  'TotalFree': 'Total Free',
  'nutritionTotals': 'Totals',
  'total_free': 'Total Available (kg)',
  'siloRead': 'Silo Reading (kg)',
  'addRationDelivery': 'Register Ration Delivery',
  'last_read': 'Last Reading',
  'period': 'Period',
  'from': 'From',
  'until': 'Until',
  'predictedEndOfFeed': 'Ration End Prediction',
  'update': 'Update',
  'groupBy': 'Group By',
  'last_days': 'Last Days',
  'between': 'Between',
  'silo_1': 'Silo 1',
  'silo_2': 'Silo 2',
  'average_consumption': 'Average Consumption',
  'average_consumption_per_animal': 'Average Consumption per Animal',
  'total': 'Total',
  'occupation_history': 'Ration Consumption',
  'occupation_percent': 'Occupation (%)',
  'week': 'Week',
  'age_range': 'Age Range',
  'cancel': 'Cancel',
  'phase': 'Phase',
  'density': 'Density',
  'semi_annual_planning': 'Weekly Planning',
  'generateReport': 'Generate Report',
  'target_date': 'Target Date/Time',
  'target_age': 'Target Age',
  'need_for_feed': 'Feed Needed',
  'accumulated_consumption': 'Accumulated Consumption',
  'weight_prediction': 'Weight Prediction',
  'target_weight_prediction': 'Predicted Weight',
  'target_weight_company': 'Company Target Weight',
  'laying-birds': 'Laying Birds',
  'alerts-history': 'Alerts History',
  'production_birds': 'Production Birds',
  'breeding_birds': 'Breeding Birds',
  'Comfortable': 'Comfortable',
  'Intermediary': 'Intermediary',
  'Critical': 'Critical',
  'Lethal': 'Lethal',
  'login': 'Login',
  'login_title': 'Login to your account.',
  'username': 'Username',
  'username_placeholder': 'username',
  'password': 'Password',
  'password_placeholder': 'password',
  'comfort_map': 'Comfort map',
  'amplitude': 'Amplitude',
  'last_reading': 'Last reading',
};
