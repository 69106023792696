<template>
  <div id="app" class="flex w-full" v-if="$route.meta.layout">
    <NewSideBar :routes="getRoutes" />
    <div class="w-full flex-grow bg-slate-100 flex flex-col overflow-x-hidden">
      <div class="px-5 py-2 flex-auto">
        <router-view :key="$route.fullPath" />
      </div>
    </div>
  </div>
  <div v-else>
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script>
import NewSideBar from '@/components/layout/NewSideBar.vue';
import { mapGetters } from 'vuex';

export default {
  components: { NewSideBar },
  computed: {
    ...mapGetters('interface', ['getRoutes']),
  },
};
</script>

<style lang="scss">
@use '@/assets/styles/tooltip.scss';
</style>
