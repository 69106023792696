export const isAuthenticated = function (to, from, next, router) {
  if (router.app.$session.exists()) {
    next();
  } else {
    next({ name: 'Login' });
  }
  return false;
};

export const hasRoles = function (to, from, next, router, store, module, role) {
  if (router.app.$session.exists()) {
    if (store.state.user.roles != null) {
      if (!role && store.state.user.roles[module]) {
        return next()
      } else if (store.state.user.roles[module]?.includes(role)) {
        return next()
      } return next(false)
    } return next(false)
  } return next({ name: 'Login' });
}