import { api as axios } from '@/plugins/axios.js';

export const getSlaughterList = async (token, limit = null, offset = null, params = null) => {
  const config = {
    method: 'get',
    url: '/v1/slaughter-list',
    headers: {
      'Authorization': 'Bearer ' + token,
    },
    params: {
      limit: limit,
      offset: offset,
      producer: params.producer,
      ordering: params.ordering ?? null,
      age: params.age,
      name: params.name,
      weight: params.weight,
      performance: params.performance,
      target_weight: params.target_weight,
      laying_birds: params.laying_birds,
      target_date: params.target_date,
    }
  };
  const { data } = await axios(config);
  return data;
};

export const getSlaughterWeightProjection = async (token, last_batch_id = null) => {
  const config = {
    method: 'get',
    url: `/v1/slaughter-weight-projection/${last_batch_id}`,
    headers: {
      'Authorization': 'Bearer ' + token,
    }
  };
  const { data } = await axios(config).catch(() => ({ data: { results: { predictions: [] } } }));
  return data;
};
